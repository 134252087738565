.sticky-section {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.sticky-container {
    position: sticky;
    top: 0;
}

.feature-section {
    transition: opacity 0.8s ease, visibility 0.8s ease, transform 0.8s ease;
    transform: translateY(20px);
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 600px;
    position: relative;
}

@media (min-width: 768px) {
    .feature-section.active {
        height: 648px;
    }
}

.image-left {
    @apply relative transition-all duration-500 left-[-700px] opacity-0
}

.image-right {
    @apply relative transition-all duration-500 right-[-700px] opacity-0
}

.text-block {
    @apply relative transition-all duration-500
}

.text-block.right{
    @apply left-[-700px]
}

.text-block.left{
    @apply right-[-700px]
}

.feature-section.active .image-left {
    @apply left-0 opacity-100
}

.feature-section.active .image-right {
    @apply right-0 opacity-100
}
.feature-section.active .text-block.right {
    @apply left-0
}

.feature-section.active .text-block.left {
    @apply right-0
}

.feature-section.active.expired {
    opacity: 0;
}

.feature-section:last-child.active.expired {
    visibility: hidden;
}